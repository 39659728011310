import { NavLink }     from 'react-router-dom'
import {
  PencilIcon,
  CurrencyEuroIcon,
  UserIcon,
  OfficeBuildingIcon
} from '@heroicons/react/solid'

import ScribeLogo from 'images/ScribeLogo.png'

export default function ApplicationSidebar() {
  return (
    <aside className="w-64 " aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded h-full dark:bg-gray-800">
        <NavLink to="/">
          <img
            src={ ScribeLogo }
            alt="Scribe logo"
            className="mx-auto mb-7 w-full"
          />
        </NavLink>
        <ul className="space-y-2">
          <li className="mb-2 rounded">
            <NavLink
              to="/signatures"
              className={ ({ isActive }) =>
                'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' +
                (isActive ? ' bg-gray-200' : '')
              }
            >
              <PencilIcon className="h-5 w-5 text-gray-500 inline-block mr-2 -mt-1" />
              Signatures Gallery
            </NavLink>
          </li>
          { /* <li className="mb-2 rounded">
            <NavLink
              to="/lead-activations"
              className={ ({ isActive }) =>
                'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' +
                (isActive ? ' bg-gray-200' : '')
              }
            >
              <UserIcon className="h-5 w-5 text-gray-500 inline-block mr-2 -mt-1" />
              Leads activation
            </NavLink>
          </li> */ }
          <li className="mb-2 rounded">
            <NavLink
              to="/subscriptions"
              className={ ({ isActive }) =>
                'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' +
                (isActive ? ' bg-gray-200' : '')
              }
            >
              <CurrencyEuroIcon className="h-5 w-5 text-gray-500 inline-block mr-2 -mt-1" />
              Subscriptions
            </NavLink>
          </li>
          <li className="mb-2 rounded">
            <NavLink
              to="/workspaces"
              className={ ({ isActive }) =>
                'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700' +
                (isActive ? ' bg-gray-200' : '')
              }
            >
              <OfficeBuildingIcon className="h-5 w-5 text-gray-500 inline-block mr-2 -mt-1" />
              Workspaces
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  )
}
