import Api from 'services/api'

export interface ISignature {
  id: number
  name: string
  workspace_name: string
  raw_html: string
}

export interface ISignaturePagination {
  size: number
  current_page: number
  total_pages: number
  total_count: number
}

export function getSignatures(attributes: {
  page: number
  subscription_kind: string
  company_name: string
  company_size: string | undefined
  workspace_id: string | undefined
  department_id: string | undefined
}): Promise<{
  data: { signature_templates: ISignature[]; pagination: ISignaturePagination }
}> {
  return Api.get(
    `/signature_templates?page=${attributes.page}&subscription_kind=${attributes.subscription_kind}&company_name=${attributes.company_name}&company_size=${attributes.company_size}&department_id=${attributes.department_id}&workspace_id=${attributes.workspace_id}`
  )
}
