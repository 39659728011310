import SignatureIframe                from 'components/signatures/SignatureIframe'
import { Loader, Pagination }         from 'components/ui'
import head                           from 'lodash/head'
import React, { useEffect, useState } from 'react'
import { useParams }                  from 'react-router-dom'
import {
  getSignatures,
  ISignature,
  ISignaturePagination
} from 'requests/signature'
import { useDebounce } from 'use-debounce'

function SignaturesList() {
  const [signatures, setSignatures] = useState<ISignature[]>([])
  const [pagination, setPagination] = useState<ISignaturePagination | null>()
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [subscriptionKind, setSubscriptionKind] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companySize, setCompanySize] = useState<string | undefined>(undefined)

  const [debouncedCompanyName] = useDebounce(companyName, 300)

  const { workspaceId, departmentId } = useParams()

  const firstSignature = head(signatures)

  function handlePageChange(page: { selected: number }) {
    if (page.selected !== currentPage) {
      setLoading(true)
      setCurrentPage(page.selected)
    }
  }

  function handleSubscriptionKindChange(
    event: React.FormEvent<HTMLSelectElement>
  ) {
    setSubscriptionKind(event.currentTarget.value)
  }

  function handleCompanyNameChange(event: React.FormEvent<HTMLInputElement>) {
    setCompanyName(event.currentTarget.value)
  }

  function handleCompanySizeChange(event: React.FormEvent<HTMLSelectElement>) {
    setCompanySize(event.currentTarget.value)
  }

  function refreshSignatures() {
    getSignatures({
      page:                 currentPage + 1,
      subscription_kind:    subscriptionKind,
      company_name:         companyName,
      company_size:         companySize,
      department_id:        departmentId,
      workspace_id:         workspaceId,
    }).then((response) => {
      setSignatures(response.data.signature_templates)
      setPagination(response.data.pagination)
      setLoading(false)
    })
  }

  useEffect(() => {
    refreshSignatures()
    // navigate('/workspaces')
  }, [
    currentPage,
    subscriptionKind,
    debouncedCompanyName,
    companySize,
  ])

  useEffect(() => {
    setSignatures([])
    if (currentPage === 0) {
      refreshSignatures()
    } else {
      setCurrentPage(0)
    }
  }, [departmentId, workspaceId])

  return (
    <>
      { !workspaceId && !departmentId && (
        <div className="grid grid-cols-4 gap-4 mb-5">
          <div>
            <label
              htmlFor="company-size"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Company name
            </label>
            <input
              type="text"
              id="company-size"
              onChange={ handleCompanyNameChange }
              value={ companyName }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          { /* <div>
            <label
              htmlFor="subscription-kind"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Subscription kind
            </label>
            <select
              id="subscription-kind"
              onChange={ handleSubscriptionKindChange }
              value={ subscriptionKind }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Subscription kind</option>
              <option value="stripe">Stripe</option>
              <option value="app_sumo">App Sumo</option>
              <option value="scribe">Free</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="company-size"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Company size
            </label>
            <select
              id="subscription-kind"
              onChange={ handleCompanySizeChange }
              value={ companySize }
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Company size</option>
              <option value="_10">1 to 10 co workers</option>
              <option value="11_50">11 to 50 co workers</option>
              <option value="51_200">51 to 200 co workers</option>
              <option value="201_500">201 to 500 co workers</option>
              <option value="501_1000">501 to 1000 co workers</option>
              <option value="1000_">More than 1000 co workers</option>
            </select>
          </div> */ }
        </div>
      ) }
      { loading && <Loader /> }
      { !loading && (
        <>
          { workspaceId && signatures && firstSignature && (
            <h1 className="text-xl mb-5">
              <strong>
                { firstSignature.workspace_name }
              </strong>
            </h1>
          ) }
          <div className="grid grid-cols-2 gap-4">
            { signatures.map((signature) => (
              <div key={ signature.id } className="card">
                <div className="mb-6 mt-2">
                  <div className="">
                    <span className="bg-blue-100 text-blue-800 font-semibold px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 mb-2">
                      { signature.workspace_name } - { signature.name }
                    </span>
                  </div>
                </div>
                <SignatureIframe content={ signature.raw_html } />
              </div>
            )) }
          </div>
        </>
      ) }
      <div className="mt-5 mb-5">
        { pagination && (
          <Pagination
            pageCount={ pagination.total_pages }
            onPageChange={ handlePageChange }
            disableInitialCallback
            forcePage={ currentPage }
          />
        ) }
      </div>
    </>
  )
}

export default SignaturesList
